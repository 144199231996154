<template>
  <div class="page">
    <div class="page-header">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addItem"
        v-has="'add'"
      >{{ $t('site.addSite') }}</el-button>
    </div>
    <div class="page-main">
      <div class="page-tools">
        <el-form :inline="true" :model="tabelSearch">
          <el-form-item v-has="'delete'">
            <el-button
              type="primary"
              icon="el-icon-plus iconfont icon-delete"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              :placeholder="$t('site.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <table-pagination
        memory="site"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        :optionMinWidth="120"
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('setParameters')"
            placement="top"
            :open-delay="1000"
            v-has="'set'"
          >
            <el-button type="text" class="btn-p0" @click="setItem(scope.row.id)">
              <i class="el-icon-setting iconfont icon-configuration"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('site.pushSiteConfiguration')"
            placement="top"
            :open-delay="1000"
            v-has="'push'"
          >
            <el-button type="text" class="btn-p0" @click="pushItem(scope.row)">
              <i class="el-icon-s-promotion iconfont icon-push"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('site.editSiteTemplate')"
            placement="top"
            :open-delay="1000"
            v-has="'edit'"
          >
            <el-button type="text" class="btn-p0" @click="editItem(scope.row.id)">
              <i class="el-icon-edit iconfont icon-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('downloadConfigurationfile')"
            placement="top"
            :open-delay="1000"
            v-has="'download'"
          >
            <el-button type="text" class="btn-p0" @click="downloadItemForModel(scope.row)">
              <i class="el-icon-download"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
    <!-- 推送任务 -->
    <dialog-configuration-push
      type="site"
      v-if="pushModelVisible"
      :title="pushModelTitle"
      :visible.sync="pushModelVisible"
      :req="pushModelReq"
      @scheduled="scheduledChange"
      :toolsDisplay="{
        modelShow: true,
        siteShow: true,
        siteDisable: true,
        keywordsShow: true
      }"
    ></dialog-configuration-push>
    <!-- 推送定时任务 -->
    <dialog-scheduled-update
      v-if="scheduledUpdateVisible"
      :visible.sync="scheduledUpdateVisible"
      :req="scheduledUpdateReq"
    ></dialog-scheduled-update>
    <!-- 下载文件 -->
    <dialog-select-model
      v-if="selectModelVisible"
      :visible.sync="selectModelVisible"
      :req="selectModelReq"
    ></dialog-select-model>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { TablePagination } from '@/components'
import { siteSearch, removeSiteBatch, downloadSite } from '@/api/site'
import { downloadFile } from '@/plugins/methods'
import DialogConfigurationPush from '../dailog-push/dialog-configuration-push'
import DialogScheduledUpdate from '../dailog-push/dialog-scheduled-update'
import DialogSelectModel from '@/components/dialog-select-model'

export default {
  name: 'Site',
  components: {
    TablePagination,
    DialogConfigurationPush,
    DialogScheduledUpdate,
    DialogSelectModel,
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        keywords: null,
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('ssite.siteName'),
          prop: 'siteName',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('ssite.description'),
          prop: 'siteDesc',
          minWidth: 200,
        },
        {
          label: this.$t('dateModified'),
          prop: 'updateTime',
          minWidth: 200,
          sortable: 'custom',
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      tableSelection: [],
      // 推送任务
      pushModelVisible: false,
      pushModelTitle: this.$t('site.pushSiteConfiguration'),
      pushModelReq: {},
      // 推送定时任务
      scheduledUpdateVisible: false,
      scheduledUpdateReq: {},
      //下载文件
      selectModelVisible: false,
      selectModelReq: {},
    }
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
    ...mapGetters('session', ['pageTabelHeight']),
    tabelParams() {
      let data = { ...this.tabelSearch }
      data.site = this.sidebarSite
      return data
    },
  },
  watch: {
    sidebarSite() {
      this.getTabelData()
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      siteSearch(this.tabelParams)
        .then((res) => {
          this.tableData = res.data.result.rows // 表格数据
          this.tabelTotal = res.data.result.totalRows // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'updateTime'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 增
    addItem() {
      this.$router.push('/site/addSite')
    },
    // 编辑
    editItem(id) {
      this.$router.push({ path: '/site/editSite', query: { id } })
    },
    // 设置设备参数
    setItem(id) {
      this.$router.push({
        path: '/site/setParam',
        query: { relationName: 'site', id },
      })
    },
    // 下载
    downloadItemForModel(row) {
      console.log('downloadItemForModel')
      this.selectModelVisible = true
      this.selectModelReq.data = row
    },
    // 下载
    downloadItem(row) {
      downloadSite({
        relationId: row.id,
        templateName: row.siteName,
      }).then((res) => downloadFile(res))
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('site.deleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          const ids = this.tableSelection.map((e) => e.id),
            args = this.tableSelection.map((e) => e.siteName)
          removeSiteBatch({ ids, args })
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 推送任务
    pushItem(row) {
      this.pushModelVisible = true
      //console.log("pushItem id:%s site%s", row.id, row.siteId)
      this.pushModelReq.row = {
        id: row.id,
        relationName: 'site',
        site: [row.siteId],
      }
    },
    // 推送定时任务
    scheduledChange(val) {
      this.scheduledUpdateVisible = true
      this.scheduledUpdateReq.data = val
    },
  },
}
</script>