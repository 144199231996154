<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <span slot="footer">
      <el-button class="dialog-btn" @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="dialog-btn" type="primary" @click="submit">{{ $t('download') }}</el-button>
    </span>
    <el-alert
      v-if="req.subtitle"
      :title="req.subtitle"
      type="info"
      center
      :closable="false"
    ></el-alert>
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item class="el-form-item" type="vertical">
        <el-radio v-model="form.type" :label="0">{{ $t('GeneralModels') }}</el-radio>
        <el-radio v-model="form.type" :label="1">{{ $t('H2Models') }}</el-radio>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>


<script>
import { downloadFile } from '@/plugins/methods'
import { downloadSite } from '@/api/site'
import { downloadGroup } from '@/api/group'

export default {
  name: 'dialog-select-model',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('ConfigurationFiledownload')
      },
    },
    req: {
      type: Object,
      
    },
  },
  data() {
    return {
      form: {
        type: 0,
      },
      
    }
  },
  methods: {
    downloadItem(row) {
      if (this.$route.path.includes('site')) {
        downloadSite({
            relationId: row.id,
            templateName: row.siteName,
            modeltype : this.form.type == 0 ? 'general' : 'H2'
            }).then((res) => downloadFile(res))
      } else {
        downloadGroup({
            relationId: row.id,
            templateName: row.groupName,
            modeltype : this.form.type == 0 ? 'general' : 'H2'
            }).then((res) => downloadFile(res))
        }
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
          let params = {
            type: this.form.type,
          }
          
          // 立即
          if (this.form.type == 0) {
            console.log('download general')
          } // 定时
          else if (this.form.type == 1) {
            console.log('download h2')
          }
          else {
            console.log('download not found', this.form.type)
          }
          this.downloadItem(this.req.data)
          this.$emit('update:visible', false)
          return true
      },
    },
  }

</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 55px 0;
}
.el-radio {
  display: block;
  margin: 30px 0;
  font-size: 30px;
}
.form {
  padding: 43px 88px;
}
</style>
